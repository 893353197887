import { FC } from 'react';
import { Dropdown as DropdownAntd, Menu } from 'antd';
import './style.less';

export interface IDataSource {
  key: number;
  value: string;
  name?: string;
  label?: string;
  children: any[];
  selectable?: boolean;
  disableCheckbox?: boolean;
}

interface PropsType {
  text: string;
  data?: IDataSource[];
}

export const Dropdown: FC<PropsType> = (props) => {
  const menu = <Menu items={props.data} />;
  return (
    <DropdownAntd className="rk-dropdown" overlay={menu} placement="bottomLeft">
      <span>{props.text}</span>
    </DropdownAntd>
  );
};
