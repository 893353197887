import { Integrator } from 'types';
import { gql } from '@apollo/client';
import client from 'apollo/apollo-client';

export async function saveIntegrator(variables: Integrator) {
  return await new Promise<{ loading: boolean; error: boolean; data: any; message: string }>(
    (resolve, reject) => {
      client
        .mutate({
          mutation: CONFIGURE_INTEGRATORS,
          variables: { ...variables },
          refetchQueries: ['Integrators'],
        })
        .then(
          ({
            data: {
              IntegratorConfigure: { error, data, message },
            },
            loading,
          }: any) => {
            resolve({ data, loading, error, message });
          },
        )
        .catch((error) => {
          reject(new Error('_server_error_'));
        });
    },
  );
}

const CONFIGURE_INTEGRATORS = gql`
  mutation IntegratorConfigure(
    $_id: ID
    $name: String!
    $description: String!
    $active: Boolean!
    $modules: [String]!
  ) {
    IntegratorConfigure(
      _id: $_id
      name: $name
      description: $description
      active: $active
      modules: $modules
    ) {
      error
      data {
        _id
        name
        description
        active
        modules
      }
      message
    }
  }
`;
