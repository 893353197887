import { IProfile } from './interface';
export interface IDefaultState {
  data: IProfile;
}

const defaultState: IDefaultState = {
  data: {
    _id: '',
    name: '',
    email: '',
    roles: [],
    language: '',
    active: false,
  },
};

export const profile = (state = defaultState, action: any) => {
  switch (action.type) {
    case 'profile.data':
      return Object.assign({}, state, { data: action.user });
    case 'profile.reset':
      return Object.assign({}, state, { data: {} });
    default:
      return state;
  }
};
