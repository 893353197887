import { Route, Switch } from 'react-router-dom';
import { Integrator } from 'pages/integrator/pages/integrator';
import { CreateIntegrator } from 'pages/integrator/pages/integrator-create';

export function RoutesIntegrators() {
  return (
    <Switch>
      <Route exact path="/integrator" component={Integrator} />
      <Route exact path="/integrator/create" component={CreateIntegrator} />
      <Route exact path={'/integrator/:id'} component={CreateIntegrator} />
    </Switch>
  );
}
