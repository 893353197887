import { Route, Switch } from 'react-router-dom';
import { DataPoints } from '../../data-point/views/data-points';

export function RoutesDataPoints() {
  return (
    <Switch>
      <Route exact path="/data-points" component={DataPoints} />
    </Switch>
  );
}
