import { CustomerCreate } from 'types';
import { gql } from '@apollo/client';
import client from 'apollo/apollo-client';

export async function SaveCustomers(variables: CustomerCreate) {
  return await new Promise<{ loading: boolean; error: boolean; data: any; message: string }>(
    (resolve, reject) => {
      client
        .mutate({
          mutation: MUTATION_CUSTOMERS,
          variables: { ...variables, state: 'Finished' },
          refetchQueries: ['Customers', 'CustomersById'],
        })
        .then(
          ({
            data: {
              CustomersConfiguration: { error, data, message },
            },
            loading,
          }: any) => {
            resolve({ data, loading, error, message });
          },
        )
        .catch((error: any) => {
          reject(new Error('_server_error_'));
        });
    },
  );
}

const MUTATION_CUSTOMERS = gql`
  mutation CustomersConfiguration(
    $_id: ID
    $name: String!
    $modules: [String]!
    $users: [String]!
    $type: String!
    $state: String!
    $active: Boolean!
    $geolocation: JSON
  ) {
    CustomersConfiguration(
      _id: $_id
      name: $name
      modules: $modules
      users: $users
      type: $type
      state: $state
      active: $active
      geolocation: $geolocation
    ) {
      error
      data {
        _id
        name
        createdAt
        weekStart
        localidad
        geolocation
        modules
        users
        time
        thresholds
        active
        state
        type
      }
      message
    }
  }
`;
