import { ILogin, IResetPassword, ISetPassword } from './interface';

const login: ILogin = {
  email: '',
  password: '',
};

const resetPassword: IResetPassword = {
  email: '',
};

const setPassword: ISetPassword = {
  newPassword: '',
  repeatPassword: '',
};

export { login, resetPassword, setPassword };
