import { useQuery, gql } from '@apollo/client';
import { Devices } from 'types';

const GET_DEVICES = gql`
  query Devices {
    Devices {
      _id
      name
      code
      type
      customerId
      location
      customer {
        _id
        name
      }
      color
      data_point {
        _id
        name
      }
      active
    }
  }
`;

export function GetDevices() {
  const { data = {}, loading, refetch, error } = useQuery(GET_DEVICES);
  return { loading, data: data.Devices as Devices[], refetch, error };
}
