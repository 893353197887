import { I18n } from 'i18n';
import { Descriptions, Tag } from 'antd';
import { useSelector } from 'react-redux';

export function DetailProfile() {
  const user = useSelector(({ profile }: any) => profile.data);

  return (
    <>
      <Descriptions key="detail-profile" size="middle" column={1}>
        <div className="rk-avatar-profile">
          <span>{user.name[0]}</span>
        </div>
        <Descriptions.Item
          label={`${I18n('name', true)} ${I18n('and', true)} ${I18n('last_name', true)}`}
        >
          {user.name}
        </Descriptions.Item>
        <Descriptions.Item label={`${I18n('email', true)}`}>
          <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
            {user.email}
            <Tag className="rk-tag" color={user.verified ? 'green' : 'red'} key={user.verified}>
              {user.verified ? I18n('verified', true) : I18n('no_verified', true)}
            </Tag>
          </div>
        </Descriptions.Item>
        <Descriptions.Item label={`${I18n('name_user', true)}`}>{user.username}</Descriptions.Item>
        <Descriptions.Item label={`${I18n('permissions', true)}`}>
          {user.roles.map((r: any) => I18n(r, true)).join(', ')}
        </Descriptions.Item>
      </Descriptions>
    </>
  );
}
