import { I18n } from 'i18n';
import { Alert } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH, faClose } from '@fortawesome/free-solid-svg-icons';
import { Table as TableUI, Tag, Input, Select } from 'antd';
import { GetUsers } from 'apollo/users';
import { User } from 'types';
import { Avatar } from 'components';
import { useState, useEffect, useCallback } from 'react';

const { Column } = TableUI;

const { Option } = Select;
interface NameProps {
  onShowDrawer: (_id?: string) => void;
}

export function ListUsers({ onShowDrawer }: NameProps) {
  const { data: dataUsers, loading: isLoading, error } = GetUsers();
  const [searchUser, setSearchUser] = useState<string>('');
  const [transformData, setTransformData] = useState<User[]>([]);
  const [status, setStatus] = useState('active');
  const [filteredStatus, setFilteredStatus] = useState<string>('');

  useEffect(() => {
    let result: User[] = [];
    dataUsers?.forEach((rows: any) => {
      if (!rows.name.toLowerCase().includes(searchUser.toLocaleLowerCase())) return;
      result.push(rows);
    });
    setTransformData(result);
  }, [dataUsers, searchUser, isLoading]);

  useEffect(() => {
    const filteredStatus = dataUsers?.filter((mystatus) =>
      status === 'active' ? mystatus.active === true : mystatus.active === false,
    );
    setTransformData(filteredStatus);
  }, [dataUsers, status]);

  const hasFiltersApplied = useCallback(() => {
    return filteredStatus !== '';
  }, [filteredStatus]);

  const handleStatusChange = (value: any) => {
    setFilteredStatus(value);
    setStatus(value);
  };

  const handleClearFilters = useCallback(() => {
    setFilteredStatus('');
    setStatus('active');

    const filteredData = dataUsers.filter((user: User) => user.active === true);
    setTransformData(filteredData);
  }, [dataUsers]);

  const getPaginationText = useCallback((total: number, range: [number, number]) => {
    const rangeText = `${range[0]}-${range[1]}`;
    return `${I18n('showing', true)} ${rangeText}`;
  }, []);

  if (error && dataUsers)
    return <Alert message={I18n('internal_server_error')} type="error" closable />;

  return (
    <>
      <div
        className="rk-container-select"
        style={{ padding: 0, marginBottom: 20, marginTop: 12, display: 'flex' }}
      >
        <div style={{ width: '50%' }}>
          <Select
            disabled={isLoading}
            loading={isLoading}
            placeholder={I18n('filter_by_state')}
            className="rk-filter-select"
            value={filteredStatus || null}
            onChange={handleStatusChange}
            style={{ width: 160, marginRight: 6 }}
          >
            <Option value="active">{I18n('active', true)}</Option>
            <Option value="inactive">{I18n('inactive', true)}</Option>
          </Select>
          {hasFiltersApplied() && (
            <button
              style={{ border: 'none', background: 'transparent', cursor: 'pointer' }}
              onClick={() => {
                handleClearFilters();
              }}
            >
              <FontAwesomeIcon icon={faClose} /> {I18n('clear_filter', true)}
            </button>
          )}
        </div>
        <div style={{ width: '50%', display: 'flex' }}>
          <Input
            disabled={dataUsers?.length > 10 ? false : true}
            onChange={(e) => setSearchUser(e.target.value)}
            placeholder={I18n('find_users')}
            size="large"
            allowClear
            style={{ width: '60%', padding: 0, marginLeft: 'auto', height: 44 }}
          />
        </div>
      </div>
      <TableUI
        dataSource={transformData}
        pagination={{
          pageSize: 10,
          showSizeChanger: false,
          showTotal: (total, range) => getPaginationText(total, range),
          position: ['bottomRight'],
        }}
        loading={isLoading}
        bordered={true}
        rowKey={(data: any, i) => (data as User)?.name + i}
        onRow={(user: User, rowIndex) => {
          return {
            onClick: () => onShowDrawer(user?._id),
          };
        }}
        rowClassName={(record: object) => {
          const farm: User = record as User;
          return farm.active ? '' : 'disabled-row';
        }}
      >
        <Column
          title={I18n('name', true)}
          dataIndex={'name'}
          key={'name'}
          render={(value) => {
            return <Avatar text={I18n(value, true)} />;
          }}
        />
        <Column
          title={I18n('email', true)}
          dataIndex={'email'}
          key={'email'}
          render={(value, user: any) => {
            return (
              <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                <>{value}</>
                <Tag className="rk-tag" color={user.verified ? 'green' : 'red'} key={user.verified}>
                  {user.verified ? I18n('verified', true) : I18n('no_verified', true)}
                </Tag>
              </div>
            );
          }}
        />
        <Column title={I18n('user_name', true)} dataIndex={'username'} key={'username'} />
        <Column
          title={I18n('permissions', true)}
          dataIndex={'roles'}
          render={(roles) => (
            <div>{I18n(roles.map((r: string) => I18n(r, true)).join(', '), true)}</div>
          )}
          key={'roles'}
        />
        <Column
          title={I18n('language', true)}
          dataIndex={'language'}
          key={'language'}
          render={(value) => I18n(value, true)}
        />
        <Column
          title={I18n('state', true)}
          dataIndex={'active'}
          key={'active'}
          render={(value) => {
            return (
              <Tag className="rk-tag" color={value ? 'green' : 'red'} key={value}>
                {value ? I18n('active', true) : I18n('inactive', true)}
              </Tag>
            );
          }}
        />
        <Column
          key="action"
          render={() => [
            <span style={{ cursor: 'pointer' }}>
              <FontAwesomeIcon icon={faEllipsisH} />
            </span>,
          ]}
        />
      </TableUI>
    </>
  );
}
