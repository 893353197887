import { useMemo } from 'react';
import { Divider } from 'antd';
import { useSelector, RootStateOrAny } from 'react-redux';
import { SelectList } from 'components';
import { actions } from 'states';
import { I18n } from 'i18n';
import { IDataSource } from 'utils';

const { useFormik } = actions;

export function ModulesList() {
  const modules = useSelector(({ modules }: RootStateOrAny) => modules.data);
  const {
    values: {
      integrator: { createIntegrator },
    },
    onChange,
  } = useFormik();

  console.log(createIntegrator);

  const selectModules = useMemo(() => {
    console.log(modules);
    let memoModules: IDataSource[] = [];
    modules.forEach((data: any) => {
      if (!createIntegrator.modules.includes(data._id)) return;
      memoModules.push({ key: data._id, value: data._id, label: I18n(data.name, true) });
    });
    return memoModules;
  }, [createIntegrator.modules, modules]);

  const onDelete = (value: string) => {
    const filtredData = createIntegrator.modules.filter((key: string) => key !== value);
    onChange(`integrator.createIntegrator.modules`, filtredData, true);
  };

  return (
    <>
      {createIntegrator.modules.length > 0 && (
        <>
          <Divider />
          <SelectList onDelete={onDelete} key="list-select-modules" data={selectModules} />
        </>
      )}
    </>
  );
}
