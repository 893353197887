import { useMemo } from 'react';
import { Alert } from 'antd';
import { GetUsersByType } from 'apollo/users';
import { SelectMultiple } from 'components';
import { actions } from 'states';
import { IDataSource } from 'utils';
import { I18n } from 'i18n';

const { useFormik } = actions;

export function SelectUsers() {
  const { data: dataUsers, loading: isLoading, error } = GetUsersByType('internal');
  const {
    values: {
      customer: { createCustomers },
    },
    onChange,
  } = useFormik();

  const listUser: IDataSource[] = useMemo(() => {
    return dataUsers?.map(({ _id, name }: any) => ({
      key: _id,
      value: _id,
      label: I18n(name, true),
      name: I18n(name, true),
    }));
  }, [dataUsers]);

  if (error) return <Alert message={error} type="error" />;

  return (
    <>
      {dataUsers && (
        <SelectMultiple
          disabled={false}
          loading={isLoading}
          id="rk-id-users"
          value={createCustomers.users}
          style={{ width: '100%' }}
          label={`${I18n('users', true)}:*`}
          onChange={(value) =>
            onChange(`customer.createCustomers.users`, value, createCustomers.users.length > 0)
          }
          data={listUser}
          placeholder={`${I18n('select_users_by_farms', true)}:*`}
        />
      )}
    </>
  );
}
