import { useSelector, RootStateOrAny, useDispatch } from 'react-redux';
import { Input, Loading, Toast } from 'components';
import { login } from 'apollo/account';
import { Form, Button, Alert } from 'antd';
import { getValidEmail, getValidMaxLength } from 'utils';
import { I18n } from 'i18n';
import { actions } from 'states';
import './login.less';

const { useFormik } = actions;

export const Login = () => {
  const dispatch = useDispatch();
  const { loading, error } = useSelector(({ account }: RootStateOrAny) => account);
  const { email, password } = useSelector(({ formik }: RootStateOrAny) => formik.account.login);
  const { onChange } = useFormik();

  const onSignIn = async () => {
    dispatch({ type: 'account.loading', loading: true });
    const { error, data, message } = await login(email, password);
    if (error) Toast.error({ key: 'error-login', message: I18n(message, true) });
    if (data) {
      dispatch({ type: 'user.data', user: data });
      localStorage.setItem('auth:userid', data._id);
      window.location.reload();
    }
    dispatch({ type: 'account.loading', loading: false });
  };

  const forgotPassword = () => {
    dispatch({
      type: 'account.resetPassword',
    });
  };

  const isDisabled = () => {
    return email && password ? false : true;
  };

  if (loading) return <Loading loading={loading} />;

  return (
    <div className="image-background">
      <div className="container-login">
        <div className="login-card">
          <Form layout="vertical" className="">
            <h3>{I18n('sign_In', true, true)}</h3>
            <Input
              size="large"
              type="email"
              placeholder={I18n('email')}
              onChange={(e) =>
                onChange(
                  `account.login.${e.target.type}`,
                  e.target.value,
                  getValidEmail(e.target.value),
                )
              }
              externalLabel={I18n('email')}
              required={true}
              disabled={false}
              id="email"
              name="email"
            />
            <Input
              size="large"
              type="password"
              onChange={(e) =>
                onChange(
                  `account.login.${e.target.type}`,
                  e.target.value,
                  getValidMaxLength(e.target.value),
                )
              }
              externalLabel={I18n('password', true)}
              placeholder={I18n('password', true)}
              required={true}
              disabled={false}
              id="password"
              name="password"
            />

            <Button
              style={{ height: 45, width: '100%' }}
              type="primary"
              disabled={isDisabled()}
              onClick={() => onSignIn()}
            >
              {I18n('accept', true, true)}
            </Button>
            <span onClick={() => forgotPassword()}>{I18n('forgot_password', true)}</span>
            {error && (
              <Form.Item>
                <Alert message={I18n(error, true)} type="error" showIcon />
              </Form.Item>
            )}
          </Form>
        </div>
      </div>
    </div>
  );
};
