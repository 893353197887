import { Spin, Descriptions, Tag, Alert } from 'antd';
import { GetUserById } from 'apollo/users';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { I18n } from 'i18n';

import { ButtonActive } from './user-active';
import { ButtonResendEmail } from './user-resend-email';

interface NameProps {
  onCloseDrawer: () => void;
  userId: string;
}

export function DetailUser({ userId, onCloseDrawer }: NameProps) {
  const { data: dataUserById, isLoading, error } = GetUserById(userId);

  if (userId && error && dataUserById)
    return <Alert message={I18n('internal_server_error', true)} type="error" />;

  return (
    <>
      {isLoading ? (
        <Spin />
      ) : (
        <>
          <Descriptions size="middle" column={1}>
            <Descriptions.Item
              label={`${I18n('name', true)} ${I18n('and', true)} ${I18n('last_name', true)}`}
            >
              {dataUserById?.name}
            </Descriptions.Item>
            <Descriptions.Item label={`${I18n('email', true)}`}>
              <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                {dataUserById?.email}
                {dataUserById?.verified ? (
                  <Tag icon={<FontAwesomeIcon icon={faCheck} />} color={'success'}>
                    {`  ${I18n('verified')}`}
                  </Tag>
                ) : (
                  <Tag icon={<FontAwesomeIcon icon={faTimes} />} color={'error'}>
                    {`  ${I18n('no_verified')}`}
                  </Tag>
                )}
              </div>
            </Descriptions.Item>
            <Descriptions.Item label={`${I18n('name_user', true)}`}>
              {dataUserById?.username}
            </Descriptions.Item>
            <Descriptions.Item label={`${I18n('state', true)}`}>
              {dataUserById?.active ? (
                <Tag icon={<FontAwesomeIcon icon={faCheck} />} color={'success'}>
                  {`  ${I18n('activate')}`}
                </Tag>
              ) : (
                <Tag icon={<FontAwesomeIcon icon={faTimes} />} color={'error'}>
                  {`  ${I18n('inactive')}`}
                </Tag>
              )}
            </Descriptions.Item>
            <Descriptions.Item label={`${I18n('permissions', true)}`}>
              {dataUserById?.roles?.map((r: string) => I18n(r, true)).join(', ')}
            </Descriptions.Item>
          </Descriptions>
          <div className="rk-button-group">
            <ButtonActive
              active={dataUserById?.active}
              userId={userId}
              onCloseDrawer={() => onCloseDrawer()}
            />
            {!dataUserById?.verified && (
              <ButtonResendEmail userId={userId} onCloseDrawer={() => onCloseDrawer()} />
            )}
          </div>
        </>
      )}
    </>
  );
}
