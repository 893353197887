import { Route, Switch } from 'react-router-dom';
import { Devices } from '../../devices/views/devices';

export function RoutesDevices() {
  return (
    <Switch>
      <Route exact path="/devices" component={Devices} />
    </Switch>
  );
}
