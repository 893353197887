import { useState } from 'react';
import { I18n } from 'i18n';
import { Button } from 'antd';
import { Toast } from 'components';
import { useHistory } from 'react-router-dom';
import { actions } from 'states';
import { saveUser } from 'apollo/users';

import { TUserSettings } from 'types';

const { useFormik } = actions;

export function Save({ userId }: any) {
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);
  const {
    values: {
      user: { createUser },
    },
  } = useFormik();

  const handleClick = async () => {
    setLoading(true);
    const { data, error, message } = await saveUser({ ...createUser, userId });
    if (error) {
      Toast.error({ key: 'error-save-users', message: I18n(message, true) });
    }
    if (!error && data) {
      history.push('/users');
      Toast.success({
        key: 'success-save-users',
        message: I18n('your_changes_were_saved_successfully', true),
      });
    }
    setLoading(false);
  };

  return (
    <>
      <Button
        type="primary"
        className="rk-button-primary"
        loading={isLoading}
        onClick={handleClick}
        disabled={isDisabled({ ...createUser, userId })}
      >
        {I18n('save_changes', true)}
      </Button>
    </>
  );
}

function isDisabled(variables: TUserSettings) {
  let disabled = true;
  let { name, userName, email, password, userId, accesWebs } = variables;
  if (!userId) {
    disabled = ![name, userName, password, email, accesWebs].every((v) => v);
  } else {
    disabled = ![name, userName, email, userId, accesWebs].every((v) => v);
  }

  return disabled;
}
