import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import { useSelector, RootStateOrAny } from 'react-redux';
import { Loading } from 'components';
import { Account } from './account';
import { AppLayout } from './layout';
import { actions } from '../states';
import esES from 'antd/lib/locale-provider/es_ES';
import enUS from 'antd/lib/locale-provider/en_US';

const { useParams } = actions;

const LangObj = {
  en: enUS,
  es: esES,
};
export const App = () => {
  const loading = useParams();
  const { data } = useSelector(({ profile }: RootStateOrAny) => profile);
  if (loading) return <Loading loading={loading} />;

  return (
    <div className="App">
      <Router>
        <ConfigProvider locale={LangObj['es']}>
          <Switch>{data._id ? <AppLayout /> : <Account />}</Switch>
        </ConfigProvider>
      </Router>
    </div>
  );
};
