import React from 'react';
import { Input as InputAntd, Form } from 'antd';
import { tuple } from 'utils';

const InputTypes = tuple('default', 'password', 'email', 'text', 'number');
const SizeTypes = tuple('large', 'middle', 'small');
export type InputsType = typeof InputTypes[number];
export type SizeType = typeof SizeTypes[number];

interface NameProps {
  id: string;
  type: InputsType;
  size?: SizeType;
  help?: string;
  disabled: boolean;
  required: boolean;
  className?: string;
  placeholder?: string;
  externalLabel?: string;
  isFormItem?: boolean;
  name?: string;
  value?: string;
  error?: string;
  touched?: boolean;
  defaultValue?: string;
  style?: React.CSSProperties;
  onChange: (e: any) => void;
}

export const Input = (props: NameProps) => {
  let {
    id,
    type,
    defaultValue,
    disabled,
    value,
    error,
    touched,
    size,
    externalLabel,
    isFormItem,
    placeholder,
    name,
    className,
    required,
    style,
    help,
  } = props;

  if (isFormItem) {
    return (
      <InputAntd
        disabled={disabled}
        type={type}
        id={id}
        placeholder={placeholder}
        name={name}
        value={value}
        size={size || 'middle'}
        required={required}
        defaultValue={defaultValue}
        className={className}
        onChange={(e: any) => props.onChange(e)}
      />
    );
  }

  return (
    <Form.Item
      help={touched || error ? help : ''}
      validateStatus={touched || error ? 'error' : 'success'}
      label={externalLabel}
    >
      <InputAntd
        disabled={disabled}
        style={{ ...style }}
        type={type}
        name={name}
        size={size || 'middle'}
        placeholder={placeholder}
        value={value}
        defaultValue={defaultValue}
        className={className}
        onChange={(e: any) => props.onChange(e)}
      />
    </Form.Item>
  );
};
