import { FC } from 'react';
import './style.less';

interface PropsType {
  hide?: boolean;
  title: string;
  description?: string;
  disabled?: boolean;
}

export const FormCard: FC<PropsType> = (props) => {
  const { title, description, children, disabled } = props;
  return (
    <div className={`form-card ${disabled ? 'form-card-disable' : ''}`}>
      <h2>{title}</h2>
      <p>{description}</p>
      <>{children}</>
    </div>
  );
};
