import { useQuery, gql } from '@apollo/client';
import { User } from 'types';

export function GetUserById(userId: string) {
  const {
    data = { UserById: undefined },
    loading: isLoading,
    error,
  } = useQuery(GET_USERSBYID, { variables: { userId } });
  return { isLoading, data: data.UserById as User, error };
}

export const GET_USERSBYID = gql`
  query UserById($userId: ID!) {
    UserById(userId: $userId) {
      _id
      name
      username
      email
      roles
      language
      verified
      active
    }
  }
`;
