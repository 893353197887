export const roles = (state = [], action: any) => {
  switch (action.type) {
    case 'roles.data':
      return Object.assign([], state, [...action.roles]);
    case 'roles.reset':
      return Object.assign([], state, []);
    default:
      return state;
  }
};
