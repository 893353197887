import { Table as TableUI } from 'antd';
import { I18n } from 'i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlug } from '@fortawesome/free-solid-svg-icons';
import { Spin } from 'components';
const { Column } = TableUI;

interface IDataDashboard {
  datatable: any;
  loading: any;
}

export function DeviceTable(props: IDataDashboard) {
  return (
    <>
      {props.loading ? (
        <Spin loading={props.loading} />
      ) : (
        <TableUI
          dataSource={props.datatable}
          bordered={true}
          pagination={false}
          rowClassName={(record) => {
            if (record.statusCode !== '201' && record.statusCode !== '406') return 'invalid__code';
            return '';
          }}
        >
          <Column
            title={I18n('device_name', true)}
            dataIndex={'devicesName'}
            key={I18n('device_name', true)}
          />
          <Column
            title={I18n('name_data_points')}
            dataIndex={'dataPointsName'}
            key={I18n('dataPointsName', true)}
          />
          <Column
            title={I18n('hoursApo')}
            dataIndex={'hoursApo'}
            key={I18n('hoursApo', true)}
            render={(entryhour) => {
              const validateEntry = entryhour === null ? I18n('no_connection', true) : entryhour;
              return validateEntry;
            }}
          />
          <Column
            title={I18n('hoursOri')}
            dataIndex={'hoursOri'}
            key={I18n('hoursOri', true)}
            render={(hour) => {
              const validate = hour === null ? I18n('no_connection', true) : hour;
              return validate;
            }}
          />
          <Column title={I18n('values', true)} dataIndex={'values'} key={I18n('values', true)} />
          <Column
            title={I18n('statusCode')}
            dataIndex={'statusCode'}
            key={I18n('statusCode', true)}
            render={(value) => {
              if (value === '201') {
                return (
                  <div
                    style={{
                      display: 'flex',
                      background: '#ebffec',
                      height: '30px',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderRadius: 4,
                    }}
                  >
                    <FontAwesomeIcon icon={faPlug} style={{ color: '#5AC624', fontSize: '16px' }} />{' '}
                    <p style={{ marginLeft: '8px', color: '#5AC624', fontSize: '12px' }}>
                      {I18n('connected', true)}
                    </p>
                  </div>
                );
              } else if (value === '406') {
                return (
                  <div
                    style={{
                      display: 'flex',
                      background: '#feffdd',
                      height: '30px',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderRadius: 4,
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faPlug}
                      style={{ color: '#f1c40f', fontSize: '16px', padding: 3 }}
                    />{' '}
                    <p style={{ marginLeft: '8px', color: '#f1c40f', fontSize: '12px' }}>
                      {I18n('connected', true)}
                    </p>
                  </div>
                );
              } else {
                return (
                  <div
                    style={{
                      display: 'flex',
                      background: '#fdf3f3',
                      height: '30px',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderRadius: 4,
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faPlug}
                      style={{ color: '#ED6868', fontSize: '16px', padding: 3 }}
                    />{' '}
                    <p style={{ marginLeft: '8px', color: '#ED6868', fontSize: '12px' }}>
                      {I18n('no_connection', true)}
                    </p>
                  </div>
                );
              }
            }}
          />
        </TableUI>
      )}
    </>
  );
}
