import { useQuery, gql } from '@apollo/client';
import { IDashboard } from 'types';

export function GetDashboardDevices(customerId: string) {
  const {
    data = { DashboardTableByCustomer: {} },
    loading,
    error,
  } = useQuery(GET_DASHBOARD_DEVICES, {
    variables: { customerId },
  });
  return { loading, data: data.DashboardTableByCustomer as IDashboard, error };
}

const GET_DASHBOARD_DEVICES = gql`
  query DashboardTableByCustomer($customerId: ID!) {
    DashboardTableByCustomer(customerId: $customerId) {
      valid
      invalid
      others
      dataTable {
        _id
        devicesName
        dataPointsName
        values
        hoursApo
        hoursOri
        statusCode
      }
    }
  }
`;
