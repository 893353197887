import { Route, Switch } from 'react-router-dom';
import { Log } from '../../customers/views/customers';
import { Create } from '../../customers/views/customers-create';

export function RoutesCustomers() {
  return (
    <Switch>
      <Route exact path="/farms" component={Log} />
      <Route exact path="/farms/create" component={Create} />
      <Route exact path="/farms/:id" component={Create} />
    </Switch>
  );
}
