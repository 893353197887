import { formik, useFormik } from './formik';
import { auth, useAuth } from './auth';
import { account } from './account';
import { header, useHeader } from './header';

import { useParams, application, modules, profile, roles } from './params';

export const reducer = {
  formik,
  auth,
  account,
  header,
  application,
  modules,
  profile,
  roles,
};

export const actions = {
  useFormik,
  useParams,
  useAuth,
  useHeader,
};
