import { List, ListCollapse } from 'components';
import { Spin, Descriptions, Tag, Alert } from 'antd';
import { GetCustomersById } from 'apollo/customers';
import { I18n } from 'i18n';
import { ButtonActive } from './active-customers';

interface NameProps {
  onCloseDrawer: () => void;
  customersId: string;
}

export function DetailCustomers({ customersId, onCloseDrawer }: NameProps) {
  const { data: datCustomerById, isLoading, error } = GetCustomersById(customersId);
  const modules =
    datCustomerById.modules?.map((r: any, i: number) => ({ value: I18n(r.name, true) })) || [];
  let thresholds: any = [];
  if (datCustomerById.thresholds) {
    Object.keys(datCustomerById.thresholds).forEach((value, i: number) => {
      thresholds.push({ label: I18n(value, true), content: [] });
      let values = datCustomerById.thresholds[value];
      if (values) {
        Object.values(values).forEach(({ max, min, color }: any) => {
          thresholds[i].content.push(
            <div style={{ display: 'flex', gap: 10 }}>
              <label className="ant-descriptions-item-label">{I18n('color', true)}</label>
              <div style={{ width: 15, height: 15, background: `${color}` }}></div>
              <label className="ant-descriptions-item-label">{I18n('max', true)}</label>{' '}
              {max === 'Infinity' ? I18n(max, true) : max}
              <label className="ant-descriptions-item-label">{I18n('min', true)}</label> {min}
            </div>,
          );
        });
      }
    });
  }
  if (customersId && error)
    return <Alert message={I18n('internal_server_error', true)} type="error" />;

  return (
    <>
      {isLoading ? (
        <div className="rk-center-spin">
          <Spin />
        </div>
      ) : (
        <>
          <Descriptions size="middle" column={1}>
            <Descriptions.Item label={`${I18n('name', true)}`}>
              {datCustomerById.name}
            </Descriptions.Item>
            <Descriptions.Item label={`${I18n('week_start', true)}`}>
              {datCustomerById.weekStart}
            </Descriptions.Item>
            <Descriptions.Item label={`${I18n('configuration_status', true)}`}>
              {I18n(datCustomerById.state, true)}
            </Descriptions.Item>
            <Descriptions.Item label={`${I18n('state', true)}`}>
              <Tag color={datCustomerById.active ? 'green' : 'red'} key={datCustomerById.active}>
                {datCustomerById.active ? I18n('active', true) : I18n('inactive', true)}
              </Tag>
            </Descriptions.Item>
            <Descriptions.Item label={`${I18n('type', true)}`}>
              {I18n(datCustomerById.type, true)}
            </Descriptions.Item>
          </Descriptions>
          <div className="rk-container-list">
            <List
              formGroupLabel={`${I18n('modules', true)}  `}
              readMoreLabel={I18n('you_can_see_them_in_detail')}
              data={modules}
            />
          </div>
          <div className="rk-container-list">
            <List
              formGroupLabel={`${I18n('users', true)}`}
              maxLength={2}
              readMoreLabel={I18n('you_can_see_them_in_detail')}
              data={datCustomerById.users}
            />
          </div>
          <div className="rk-container-list">
            <ListCollapse
              formGroupLabel={`${I18n('thresholds', true)} `}
              readMoreLabel={I18n('you_can_see_them_in_detail')}
              data={thresholds}
            />
          </div>
          <div className="rk-button-group">
            <ButtonActive
              active={datCustomerById.active}
              customersId={customersId}
              onCloseDrawer={() => onCloseDrawer()}
            />
          </div>
        </>
      )}
    </>
  );
}
