import moment from 'moment';

export const tuple = <T extends string[]>(...args: T) => args;
export const tupleNum = <T extends number[]>(...args: T) => args;
export const SizeTypes = ['large', 'middle', 'small'];

export function makeid(length: number) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export const redex = {
  email:
    /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/,
  coordinates:
    /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/,
  number: /^\d+$/,
};

export function getValidEmail(value: string) {
  const re = redex.email;
  return value && re.test(value) ? false : true;
}

export function getValidMaxLength(value: string) {
  if (value) {
    if (/.{9,}/.test(value)) return true;
    else if (/.{4,}/.test(value)) return true;
  }
  return false;
}

export function getLanguage(language: string) {
  moment.locale(language || 'es');
  return language || 'es';
}
