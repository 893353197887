import { IAction, IAuth } from './interface';

const initialState: IAuth = {
  loading: false,
  data: {
    _id: undefined,
    name: undefined,
    email: undefined,
    roles: [],
    language: undefined,
    active: false,
  },
  reset: {},
};

export const auth = (state = initialState, action: IAction) => {
  switch (action.type) {
    case 'auth.data':
      return Object.assign({}, state, { data: action.user });
    case 'auth.reset':
      return Object.assign({}, state, { data: {} });
    default:
      return state;
  }
};
