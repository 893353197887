import { useQuery, gql } from '@apollo/client';
import { Integrator } from 'types';

const GET_INTEGRATOR = gql`
  query Integrators {
    Integrators {
      _id
      name
      description
      active
      apiKey
      modules
      modulesNames
    }
  }
`;

export function GetIntegrator() {
  const { data = {}, loading, refetch, error } = useQuery(GET_INTEGRATOR);
  return { loading, data: data.Integrators as Integrator[], refetch, error };
}
