import { Card } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlug } from '@fortawesome/free-solid-svg-icons';
import { I18n } from 'i18n';
import { Divider } from 'antd';
import { Spin } from 'components';

interface IError {
  interference: string;
  loading: boolean;
}

export function DevicesError(props: IError) {
  return (
    <Card
      className="card_info"
      style={{
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        height: '180px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: '16px',
      }}
      bodyStyle={{ padding: 0 }}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {/* Ícono */}
        <div
          style={{
            marginRight: '16px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '40px',
            height: '40px',
          }}
        >
          <FontAwesomeIcon icon={faPlug} style={{ color: '#ED6868', fontSize: '24px' }} />
        </div>
        {/* Título y descripción */}
        <div>
          <div style={{ fontSize: '16px', fontWeight: 600, color: '#0f1c30' }}>
            {I18n('no_connection', true)}
          </div>
          <div style={{ fontSize: '12px', color: '#6c757d' }}>
            {I18n('number_of_sensors_with_data_success_but_duplication_of_time', true)}
          </div>
        </div>
      </div>
      <Divider />
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          fontSize: '40px',
          fontWeight: 700,
          color: '#0f1c30',
          minHeight: '50px',
        }}
      >
        {props.loading ? <Spin loading={props.loading} /> : null}
        {props.interference}
      </div>
    </Card>
  );
}
