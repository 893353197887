import { Form } from 'antd';
import { useParams } from 'react-router-dom';
import { GetIntegratorById } from 'apollo/integrator';
import { PageContent, Space } from 'components';
import { Information, Save } from '../components/integrator-create/';
import { I18n } from 'i18n';
import { Loading } from 'components';
interface NameProps {
  disabled: boolean;
}

export function CreateIntegrator({ disabled }: NameProps) {
  const { id } = useParams<{ id: string }>();
  const { data: dataIntegratorById, isLoading } = GetIntegratorById(id);
  if (id && isLoading) return <Loading loading={isLoading} />;

  return (
    <PageContent
      title={I18n('create_integrator', true, true)}
      backIcon={false}
      extra={[<Save codeId={id} />]}
    >
      <Space.Layout>
        <Form layout="vertical" className="">
          <Information
            isLoading={isLoading}
            dataIntegratorById={dataIntegratorById}
            disabled={disabled}
          />
        </Form>
      </Space.Layout>
    </PageContent>
  );
}
