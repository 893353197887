import { useMemo } from 'react';
import { useSelector, RootStateOrAny } from 'react-redux';
import { SelectSearch } from 'components';
import { actions } from 'states';
import { I18n } from 'i18n';

const { useFormik } = actions;

export function Modules() {
  const modules = useSelector(({ modules }: RootStateOrAny) => modules.data);
  const {
    values: {
      customer: { createCustomers },
    },
    onChange,
  } = useFormik();

  const listModules = useMemo(() => {
    return modules.map(({ _id, name, type }: any) => ({
      value: _id,
      name: I18n(name, true),
      OptGroup: I18n(type, true),
    }));
  }, [modules]);

  const optGroups = useMemo(() => {
    return modules.reduce((memo: any, data: any) => {
      if (!memo.includes(I18n(data.type, true))) memo.push(I18n(data.type, true));
      return memo;
    }, []);
  }, [modules]);

  return (
    <>
      {modules && (
        <SelectSearch
          id="customers-modules"
          value={createCustomers.modules}
          style={{ width: '100%' }}
          label={`${I18n('modules', true)}:*`}
          onChange={(value: string) => onChange(`customer.createCustomers.modules`, value, true)}
          data={listModules}
          placeholder={`${I18n('modules', true)}:*`}
          className="rk-select-multiple"
          optGroups={optGroups}
        />
      )}
    </>
  );
}
