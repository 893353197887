import { IAccount, IAction } from './interface';

const defaultState: IAccount = {
  loading: false,
  error: '',
  sent: '',
  resetPassword: false,
  setPassword: false,
};

export const account = (state = defaultState, action: IAction) => {
  switch (action.type) {
    case 'account.loading':
      console.log(action.loading);

      return Object.assign({}, state, { loading: action.loading, sent: null });
    case 'account.error':
      return Object.assign({}, state, { error: action.message, sent: null });
    case 'account.reset':
      return defaultState;
    case 'account.resetPassword':
      return Object.assign({}, state, { resetPassword: !state.resetPassword });
    case 'account.setPassword':
      return Object.assign({}, state, { setPassword: !state.setPassword });
    default:
      return state;
  }
};
