import { Table as TableUI, Tag, Input } from 'antd';
import { useHistory } from 'react-router-dom';
import { useCustomers } from 'apollo/customers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState, useEffect, useCallback } from 'react';
import {
  faClock,
  faCheck,
  faPlane,
  faListCheck,
  faEllipsisH,
} from '@fortawesome/free-solid-svg-icons';
import { ObjectString } from 'utils';
import { Dropdown } from 'components';
import { Customer } from 'types';
import { I18n } from 'i18n';

const { Column } = TableUI;

interface NameProps {
  onShowDrawer: (id: string) => void;
}

const STATE: ObjectString = {
  configure_basic: { color: 'default', icon: faClock },
  configure_users: { color: 'yellow', icon: faClock },
  configure_data_points: { color: 'yellow', icon: faClock },
  configure_devices: { color: 'yellow', icon: faClock },
  Finished: { color: 'green', icon: faCheck },
};

const TYPE: ObjectString = {
  test: { color: 'default', icon: faListCheck },
  pilot: { color: 'yellow', icon: faPlane },
  client: { color: 'green', icon: faCheck },
};

export function CustomersList({ onShowDrawer }: NameProps) {
  const history = useHistory();
  const { data, error, loading } = useCustomers();
  const [filteredStatus, setFilteredStatus] = useState<boolean[]>([true]);
  const [searchCustomer, setSearchCustomer] = useState<string>('');
  const [transformData, setTransformData] = useState<Customer[]>([]);
  if (error) history.push('/error-server');

  useEffect(() => {
    let result: Customer[] = [];
    data.forEach((rows: any) => {
      if (!rows.name.toLowerCase().includes(searchCustomer.toLocaleLowerCase())) return;
      result.push(rows);
    });
    setTransformData(result);
  }, [data, searchCustomer, loading]);

  const getPaginationText = useCallback((total: number, range: [number, number]) => {
    const rangeText = `${range[0]}-${range[1]}`;
    return `${I18n('showing', true)} ${rangeText}`;
  }, []);

  return (
    <>
      <div className="container-customer" style={{ padding: 0, marginBottom: 12, display: 'flex' }}>
        <div style={{ width: '50%' }}></div>
        <div style={{ width: '50%', display: 'flex' }}>
          <Input
            disabled={data.length > 10 ? false : true}
            onChange={(e) => setSearchCustomer(e.target.value)}
            placeholder={I18n('find_customer')}
            size="large"
            allowClear
            style={{ width: '60%', padding: 0, marginLeft: 'auto' }}
          />
        </div>
      </div>
      <TableUI<Customer>
        dataSource={transformData}
        pagination={{
          pageSize: 10,
          showSizeChanger: false,
          showTotal: (total, range) => getPaginationText(total, range),
          position: ['bottomRight'],
        }}
        loading={loading}
        bordered={true}
        rowClassName={(record: object) => {
          const farm: Customer = record as Customer;
          return farm.active ? '' : 'disabled-row';
        }}
        onRow={(farm: Customer, rowIndex) => {
          return {
            onClick: () => onShowDrawer(farm._id),
          };
        }}
        onChange={(pagination, filters) => {
          const statusFilters = filters.active as boolean[];
          setFilteredStatus(statusFilters && statusFilters.length ? statusFilters : [true]);
        }}
      >
        {['name'].map((field: string) => (
          <Column title={I18n(field, true)} dataIndex={field} key={field} />
        ))}
        <Column title={I18n('week_start', true)} dataIndex={'weekStart'} key={'weekStart'} />
        <Column
          title={I18n('modules', true)}
          dataIndex={'modulesNames'}
          render={(modules) => (
            <div>
              {I18n(modules[0], true)}{' '}
              <Dropdown
                data={modules.map((r: string, i: number) => ({ key: i, label: I18n(r, true) }))}
                text={modules.length >= 2 ? `+${modules.length}` : ''}
              />
            </div>
          )}
          key={'modulesNames'}
        />
        <Column
          title={I18n('users', true)}
          dataIndex={'usersName'}
          render={(users) => (
            <div>
              {users[users.length - 1]}{' '}
              <Dropdown
                data={users.map((r: string, i: number) => ({ key: i, label: r }))}
                text={users.length >= 2 ? `+${users.length}` : ''}
              />
            </div>
          )}
          key={'usersName'}
        />
        <Column
          title={I18n('type', true)}
          dataIndex={'type'}
          key={'type'}
          render={(value) => (
            <Tag
              className="rk-tag"
              icon={<FontAwesomeIcon icon={TYPE[value].icon} />}
              color={`${TYPE[value].color}`}
              key={value}
            >
              {I18n(value, true)}
            </Tag>
          )}
        />
        <Column
          title={I18n('configuration_status', true)}
          dataIndex={'state'}
          key={'state'}
          render={(value) => (
            <Tag
              className="rk-tag"
              icon={<FontAwesomeIcon icon={STATE[value].icon} />}
              color={`${STATE[value].color}`}
              key={value}
            >
              {I18n(value, true)}
            </Tag>
          )}
        />
        <Column
          title={I18n('state', true)}
          dataIndex={'active'}
          key={'active'}
          filteredValue={filteredStatus || null}
          filters={[
            { text: 'Activo', value: true },
            { text: 'Inactivo', value: false },
          ]}
          onFilter={(value, record: any) => record.active === value}
          render={(key) => (
            <Tag className="rk-tag" color={key ? 'green' : 'red'} key={key}>
              {key ? I18n('active', true) : I18n('inactive', true)}
            </Tag>
          )}
        />
        <Column
          key="action"
          render={() => [
            <span style={{ cursor: 'pointer' }}>
              <FontAwesomeIcon icon={faEllipsisH} />
            </span>,
          ]}
        />
      </TableUI>
    </>
  );
}
