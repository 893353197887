import { Input, Loading, Toast } from 'components';
import { Form, Button } from 'antd';
import { resetPassword } from 'apollo/account';
import { useSelector, RootStateOrAny, useDispatch } from 'react-redux';
import { actions } from 'states';
import { I18n } from 'i18n';
import '../login/login.less';

const { useFormik } = actions;

export const ResetPassword = () => {
  const dispatch = useDispatch();
  const { loading } = useSelector(({ account }: RootStateOrAny) => account);
  const { email } = useSelector(({ formik }: RootStateOrAny) => formik.account.resetPassword);
  const { onChange } = useFormik();

  const onSubmit = async () => {
    dispatch({ type: 'account.loading', loading: true });
    const { data, error, message } = await resetPassword(email);
    if (error) Toast.error({ key: 'error-reset-password', message: I18n(message, true) });
    if (data) Toast.success({ key: 'success-reset-password', message: I18n(message, true) });
    dispatch({ type: 'account.loading', loading: false });
  };

  const onBack = () => {
    dispatch({
      type: 'account.resetPassword',
    });
  };

  const isDisabled = () => {
    return email ? false : true;
  };

  if (loading) return <Loading loading={loading} />;

  return (
    <div className="image-background">
      <div className="container-login">
        <div className="login-card">
          <Form layout="vertical">
            <h3>{I18n('reset_password', true)}</h3>
            <Input
              type="email"
              onChange={(e) => onChange(`account.resetPassword.${e.target.type}`, e.target.value)}
              externalLabel={I18n('email', true)}
              placeholder={I18n('email', true)}
              required={true}
              disabled={false}
              id="email"
              name="email"
              size="large"
            />
            <Button
              style={{ height: 45, width: '100%' }}
              type="primary"
              disabled={isDisabled()}
              onClick={() => onSubmit()}
              htmlType="submit"
            >
              {I18n('send', true, true)}
            </Button>
            <span onClick={() => onBack()}>{I18n('return', true)}</span>
          </Form>
        </div>
      </div>
    </div>
  );
};
