import { FormCard, Space } from 'components';

import { I18n } from 'i18n';
import { SelectUsers } from './users';
import { UsersList } from './list-users';
interface NameProps {
  disabled: boolean;
}

export function ConfigUsers({ disabled }: NameProps) {
  return (
    <>
      <FormCard disabled={disabled} title={I18n('users')} description={I18n('add_users_by_acces')}>
        <Space.Columns>
          <SelectUsers />
        </Space.Columns>
        <UsersList />
      </FormCard>
    </>
  );
}
