import { useState } from 'react';
import { I18n } from 'i18n';
import { Toast } from 'components';
import { useHistory } from 'react-router-dom';
import { actions } from 'states';
import { saveIntegrator } from 'apollo/integrator';
import { Button } from 'antd';
import { TIntegrator } from 'types';

const { useFormik } = actions;

export function Save({ codeId }: any) {
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);
  const {
    values: {
      integrator: { createIntegrator },
    },
  } = useFormik();

  const handleClick = async () => {
    setLoading(true);
    const { error, message } = await saveIntegrator({ ...createIntegrator, _id: codeId });
    if (error) {
      Toast.error({ key: 'error-save-integrator', message: I18n(message, true) });
    }
    if (!error && message === 'Integrator configured successfully') {
      history.push('/integrator');
      Toast.success({
        key: 'success-save-integrator',
        message: I18n('your_changes_were_saved_successfully', true),
      });
    }
    setLoading(false);
  };

  return (
    <>
      <Button
        type="primary"
        className="rk-button-primary"
        loading={isLoading}
        onClick={handleClick}
        disabled={isDisabled({ ...createIntegrator, codeId })}
      >
        {I18n('save_changes', true)}
      </Button>
    </>
  );
}

function isDisabled(variables: TIntegrator) {
  let disabled = true;
  let { name, description, codeId } = variables;
  if (!codeId) {
    disabled = ![name, description].every((v) => v);
  } else {
    disabled = ![name, description, codeId].every((v) => v);
  }
  return disabled;
}
