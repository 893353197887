import { useState } from 'react';
import { I18n } from 'i18n';
import { Button, Tooltip } from 'antd';
import { Toast } from 'components';
import { ResendEmailUser } from 'apollo/users';

interface NameProps {
  onCloseDrawer: () => void;
  userId: string;
}

export function ButtonResendEmail({ userId, onCloseDrawer }: NameProps) {
  const [isLoading, setLoading] = useState(false);

  const handleClick = async () => {
    setLoading(true);
    const { data, error, message } = await ResendEmailUser({ _id: userId });
    if (error) {
      Toast.error({ key: 'error-save-users', message: I18n(message) });
    }
    if (!error && data) {
      onCloseDrawer();
      Toast.success({
        key: 'success-save-users',
        message: I18n('your_changes_were_saved_resend_email'),
      });
    }
    setLoading(false);
  };

  return (
    <Tooltip
      placement="top"
      title={I18n('the_email_will_be_forwarded_to_verify_the_users_email_again')}
    >
      <Button
        loading={isLoading}
        type="primary"
        className="rk-button-primary"
        disabled={isLoading}
        onClick={handleClick}
      >
        {I18n('resend_email', true)}
      </Button>
    </Tooltip>
  );
}
