import { useDispatch, useSelector, RootStateOrAny } from 'react-redux';

export const useFormik = () => {
  const values = useSelector(({ formik }: RootStateOrAny) => formik);
  const dispatch = useDispatch();

  // Función que se ejecuta conforme el usuario escribe algo
  const onChange = (key: string, value: any, valid?: boolean) => {
    return dispatch({ type: 'reusable-' + key, key, value, valid });
  };

  return {
    values,
    onChange,
  };
};
