import { Select } from 'antd';
import { useCustomers } from 'apollo/customers';
import { useHistory } from 'react-router-dom';
import { useMemo } from 'react';
import { I18n } from 'i18n';
import { useEffect } from 'react';
const { Option } = Select;

interface IPropsFilterDevices {
  handleChange: (value: string) => void;
  selectCustomerId: string;
}

const expensiveCalculation = (data: any) => {
  const filterData = data.filter((customer: any) => customer.active === true);
  return filterData;
};

export function FilterDevice(props: IPropsFilterDevices) {
  const history = useHistory();
  const { data, error, loading } = useCustomers();
  if (error) history.push('/error-server');

  const calculation = useMemo(() => expensiveCalculation(data), [data]);

  useEffect(() => {
    if (calculation.length > 0 && !props.selectCustomerId) {
      props.handleChange(calculation[1]._id);
    }
  }, [props, calculation]);

  return (
    <Select
      style={{ width: 200 }}
      placeholder={I18n('select_a_client')}
      onChange={props.handleChange}
      value={props.selectCustomerId}
      disabled={loading}
    >
      {calculation.map((customer: { _id: string; name: string }) => (
        <Option key={customer._id} value={customer._id}>
          {customer.name}
        </Option>
      ))}
    </Select>
  );
}
