import { useState, useEffect } from 'react';
import { DevicesError } from '../components/devices-error/error';
import { DevicesActive } from '../components/devices-ative/active';
import { DevicesWarning } from '../components/device-warning/warning';
import { DeviceTable } from '../components/device-table/table-device';
import { FilterDevice } from '../components/divice-filter/filter';
import { GetDashboardDevices } from 'apollo/dashboard/Getdashboard-devices';
import { Spin } from 'components';
import { I18n } from 'i18n';

export function Dashboard() {
  const [selectedCustomerId, setSelectedCustomerId] = useState<string>('');
  const [showspin, setShowSpin] = useState<boolean>(true);

  const { data, error, loading } = GetDashboardDevices(selectedCustomerId);

  useEffect(() => {
    setTimeout(() => {
      setSelectedCustomerId(selectedCustomerId);
      setShowSpin(false);
    }, 10000);
  }, []);

  const handleChange = (value: string) => {
    setSelectedCustomerId(value);
  };

  return (
    <div className="father__container">
      <div className="content">
        <div className="sub__container filter">
          <FilterDevice handleChange={handleChange} selectCustomerId={selectedCustomerId} />
        </div>
        {selectedCustomerId == '' ? (
          <Spin loading={showspin} />
        ) : (
          <>
            <h1 className="__title">{I18n('sensors_that_are_sending_data')}</h1>
            <span style={{ color: 'gray' }}>
              {I18n('metrics_for_the_number_of_connected_sensors')}
            </span>
            <div className="sub__container table__device">
              <DevicesActive validate={data.valid} loading={loading}></DevicesActive>
              <DevicesWarning warning={data.invalid} loading={loading}></DevicesWarning>
              <DevicesError interference={data.others} loading={loading}></DevicesError>
            </div>
            <h1 className="__title">{I18n('register_sensors_that_are_sending_data')}</h1>
            <div className="device-status-table">
              <DeviceTable datatable={data.dataTable} loading={loading}></DeviceTable>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
