import { gql, useMutation } from '@apollo/client';
import { Devices } from 'types';

export function useDeviceRemove(_Id: string) {
  const [UserRemove, { data, loading, error }] = useMutation(REMOVE_USERS, {
    variables: { _id: _Id },
    refetchQueries: ['UsersByCustomer'],
  });
  return {
    UserRemove,
    data: data?.DevicesRemove.data as Devices,
    loading,
    message: data?.DevicesRemove.message,
    error,
  };
}

const REMOVE_USERS = gql`
  mutation DevicesRemove($_id: ID!) {
    DevicesRemove(_id: $_id) {
      error
      data {
        _id
        name
        code
        type
        location
        color
        data_points
        data_points_name
        active
      }
      message
    }
  }
`;
