import { gql } from '@apollo/client';
import client from 'apollo/apollo-client';

type ActiveUser = {
  _id: string;
};

export async function ResendEmailUser({ _id }: ActiveUser) {
  return await new Promise<{ loading: boolean; error: boolean; data: any; message: string }>(
    (resolve, reject) => {
      client
        .mutate({
          mutation: RESETEMAIL_USERS,
          variables: { userId: _id },
          refetchQueries: ['Users'],
        })
        .then(
          ({
            data: {
              UserResendEmail: { error, data, message },
            },
            loading,
          }: any) => {
            resolve({ data, loading, error, message });
          },
        )
        .catch((error) => {
          reject(new Error('_server_error_'));
        });
    },
  );
}

const RESETEMAIL_USERS = gql`
  mutation UserResendEmail($userId: ID!) {
    UserResendEmail(userId: $userId) {
      error
      data {
        _id
        name
        email
        roles
        language
        active
      }
      message
    }
  }
`;
