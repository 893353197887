import { Route, Switch } from 'react-router-dom';
import { Dashboard } from '../../dashboard/views/dashboard';

export function RouteDashboard() {
  return (
    <Switch>
      <Route exact path="/dashboard" component={Dashboard}></Route>
    </Switch>
  );
}
