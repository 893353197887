import { useState, useEffect, useMemo, useCallback } from 'react';
import { Table as TableUI, Tag, Input, Select } from 'antd';
import { I18n } from 'i18n';
import { Toast } from 'components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { Devices } from 'types';
import { GetDevices } from 'apollo/devices';
import { faClose } from '@fortawesome/free-solid-svg-icons';

const { Column } = TableUI;

const { Option } = Select;
interface NameProps {
  onShowDrawer: (_id?: string) => void;
}

export function ListDevices({ onShowDrawer }: NameProps) {
  const { data: dataDevices, loading: isLoading, error } = GetDevices();
  const [filteredCustomer, setFilteredCustomer] = useState<string | null>(null);
  const [status, setStatus] = useState('active');
  const [filteredTypes, setFilteredTypes] = useState<string>('');
  const [searchDevices, setSearcDevices] = useState<string>('');
  const [transformData, setTransformData] = useState<Devices[]>([]);
  const [filteredStatus, setFilteredStatus] = useState<string>('');
  if (error) {
    Toast.error({ key: 'error-list-devices', message: I18n('_server_error_', true) });
  }

  const uniqueCustomer = useMemo(() => {
    return Array.from(new Set(transformData.map((device) => device.customer.name)));
  }, [transformData]);

  const uniqueType = useMemo(() => {
    return Array.from(new Set(transformData.map((device) => device.type)));
  }, [transformData]);

  useEffect(() => {
    let result: Devices[] = [];
    dataDevices?.forEach((rows: any) => {
      if (!rows.name.toLowerCase().includes(searchDevices.toLocaleLowerCase())) return;
      result.push(rows);
    });
    setTransformData(result);
  }, [dataDevices, searchDevices, isLoading]);

  useEffect(() => {
    if (!dataDevices) return;
    const filteredData = dataDevices
      .filter((device) => !filteredCustomer || device.customer.name === filteredCustomer)
      .filter((device) => !filteredTypes || device.type === filteredTypes)
      .filter((device) => (status === 'active' ? device.active === true : device.active === false));
    setTransformData(filteredData);
  }, [filteredCustomer, filteredTypes, status, dataDevices]);

  const hasFiltersApplied = useCallback(() => {
    return filteredCustomer !== null || filteredTypes !== '' || filteredStatus !== '';
  }, [filteredStatus, filteredCustomer, filteredTypes]);

  const handleStatusChange = (value: any) => {
    setFilteredStatus(value);
    setStatus(value);
  };

  const handleClearFilters = useCallback(() => {
    setFilteredCustomer(null);
    setFilteredTypes('');
    setFilteredStatus('');
    setStatus('active');
    const filteredData = dataDevices.filter((dataPoint: Devices) => dataPoint.active === true);
    setTransformData(filteredData);
  }, [dataDevices]);

  const getPaginationText = useCallback((total: number, range: [number, number]) => {
    const rangeText = `${range[0]}-${range[1]}`;
    return `${I18n('showing', true)} ${rangeText}`;
  }, []);

  return (
    <>
      <div
        className="rk-container-select"
        style={{ padding: 0, marginBottom: 20, marginTop: 12, display: 'flex' }}
      >
        <div style={{ width: '50%' }}>
          <Select
            disabled={isLoading}
            loading={isLoading}
            className="rk-filter-select"
            placeholder={I18n('filter_by_customer', true)}
            style={{ width: 160, marginRight: 6 }}
            value={filteredCustomer || null}
            onChange={(value) => {
              setFilteredCustomer(value);
            }}
          >
            {uniqueCustomer.map((name) => (
              <Option key={name} value={name}>
                {name}
              </Option>
            ))}
          </Select>
          <Select
            disabled={isLoading}
            loading={isLoading}
            className="rk-filter-select"
            placeholder={I18n('filter_by_type', true)}
            style={{ width: 160, marginRight: 6 }}
            value={filteredTypes || null}
            onChange={(value) => {
              setFilteredTypes(value);
            }}
          >
            {uniqueType.map((name) => (
              <Option key={name} value={name}>
                {I18n(name)}
              </Option>
            ))}
          </Select>
          <Select
            disabled={isLoading}
            loading={isLoading}
            placeholder={I18n('filter_by_state', true)}
            className="rk-filter-select"
            value={filteredStatus || null}
            onChange={handleStatusChange}
            style={{ width: 160, marginRight: 6 }}
          >
            <Option value="active">{I18n('active', true)}</Option>
            <Option value="inactive">{I18n('inactive', true)}</Option>
          </Select>
          {hasFiltersApplied() && (
            <button
              style={{ border: 'none', background: 'transparent', cursor: 'pointer' }}
              onClick={() => {
                handleClearFilters();
              }}
            >
              <FontAwesomeIcon icon={faClose} /> {I18n('clear_filter', true)}
            </button>
          )}
        </div>
        <div style={{ width: '50%', display: 'flex' }}>
          <Input
            disabled={dataDevices?.length > 10 ? false : true}
            onChange={(e) => setSearcDevices(e.target.value)}
            placeholder={I18n('find_device')}
            size="large"
            allowClear
            style={{ width: '60%', padding: 0, marginLeft: 'auto', height: 44 }}
          />
        </div>
      </div>
      <TableUI
        dataSource={transformData}
        pagination={{
          pageSize: 10,
          showSizeChanger: false,
          showTotal: (total, range) => getPaginationText(total, range),
          position: ['bottomRight'],
        }}
        loading={isLoading}
        bordered={true}
        rowKey={(data) => data._id}
        onRow={(data: Devices, rowIndex) => {
          return {
            onClick: () => onShowDrawer(data?._id),
          };
        }}
      >
        {['name'].map((field) => (
          <Column
            title={I18n(field, true)}
            dataIndex={field}
            key={field}
            render={(key) => I18n(key, true)}
          />
        ))}
        <Column title={I18n('code', true)} dataIndex={'code'} key={I18n('code', true)} />
        <Column
          title={I18n('data_points_name', true)}
          dataIndex={'data_point'}
          key={I18n('data_point', true)}
          render={(key) => key.name}
        />
        <Column
          title={I18n('customer', true)}
          dataIndex={'customer'}
          key={I18n('customer', true)}
          render={(key) => key.name}
        />
        <Column
          title={I18n('type', true)}
          dataIndex={'type'}
          key={I18n('type', true)}
          render={(key) => I18n(key, true)}
        />
        <Column
          title={I18n('state', true)}
          dataIndex={'active'}
          key={'active'}
          render={(value) => {
            return (
              <Tag className="rk-tag" color={value ? 'green' : 'red'} key={value}>
                {value ? I18n('active', true) : I18n('inactive', true)}
              </Tag>
            );
          }}
        />
        <Column
          width={80}
          title={I18n('color', true)}
          dataIndex={'color'}
          key={'color'}
          render={(color) => <div style={{ width: 15, height: 15, background: color }} />}
        />
        <Column
          key="action"
          width={50}
          render={() => [
            <span style={{ cursor: 'pointer' }}>
              <FontAwesomeIcon icon={faEllipsisH} />
            </span>,
          ]}
        />
      </TableUI>
    </>
  );
}
