/* eslint-disable jsx-a11y/anchor-is-valid */
import { IDataSourceSelectList } from './interface';
import './style.less';

interface NameProps {
  data: IDataSourceSelectList[] | any;
  maxLength?: number;
  readMoreLabel: string;
  formGroupLabel?: string;
}

export const List = (props: NameProps) => {
  if (!props.formGroupLabel) {
    return (
      <ul className="rk-select-list" style={{}}>
        {props.data &&
          props.data.map((r: any, i: number) => {
            return props.maxLength && i < props.maxLength ? (
              <li className="rk-select-list-item" style={{}}>
                {r.label || r.name || r.value}
              </li>
            ) : (
              <li className="rk-select-list-item" style={{}}>
                {r.label || r.name || r.value}
              </li>
            );
          })}
        {props.maxLength && (
          <li className="rk-select-list-item">
            <a>{`+${props.data.length - props.maxLength}, 
                    ${props.readMoreLabel}`}</a>
          </li>
        )}
      </ul>
    );
  }

  return (
    <>
      <label className="ant-descriptions-item-label">{props.formGroupLabel}</label>
      <ul className="rk-select-list">
        {props.data &&
          props.data.map((r: any, i: number) => {
            return props.maxLength ? (
              props.maxLength && i < props.maxLength && (
                <li className="rk-select-list-item" style={{}}>
                  {r.label || r.name || r.value}
                </li>
              )
            ) : (
              <li className="rk-select-list-item" style={{}}>
                {r.label || r.name || r.value}
              </li>
            );
          })}
        {props.maxLength && props.maxLength < props.data?.length && (
          <li className="rk-select-list-item">
            <a href="#">{`+${props.data?.length - props.maxLength}, 
                    ${props.readMoreLabel}`}</a>
          </li>
        )}
      </ul>
    </>
  );
};
