import { gql } from '@apollo/client';
import client from 'apollo/apollo-client';

type ActiveVars = {
  _id: string;
};

export async function ActiveCustomers({ _id }: ActiveVars) {
  return await new Promise<{ loading: boolean; error: boolean; data: any; message: string }>(
    (resolve, reject) => {
      client
        .mutate({
          mutation: MUTATION_CUSTOMER,
          variables: { _id },
          refetchQueries: ['Customers', 'CustomersById'],
        })
        .then(
          ({
            data: {
              CustomerActive: { error, data, message },
            },
            loading,
          }: any) => {
            resolve({ data, loading, error, message });
          },
        )
        .catch((error: any) => {
          reject(new Error('_server_error_'));
        });
    },
  );
}

const MUTATION_CUSTOMER = gql`
  mutation CustomerActive($_id: ID!) {
    CustomerActive(_id: $_id) {
      error
      data {
        _id
        name
        createdAt
        weekStart
        localidad
        geolocation
        modules
        users
        time
        thresholds
        active
        state
        type
      }
      message
    }
  }
`;
