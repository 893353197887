import { useState, useEffect } from 'react';
import { Form, Typography, Alert } from 'antd';
import { useParams } from 'react-router-dom';
import { GetCustomersById } from 'apollo/customers';
import { PageContent, Space } from 'components';
import { actions } from 'states';
import {
  InfoGeneral,
  ConfigModules,
  ConfigUsers,
  WizardBody,
  FinalDetails,
} from '../components/customers-create';
import { I18n } from 'i18n';

const KEYS = [
  { type: '_id', value: '_id' },
  { type: 'name', value: 'name' },
  { type: 'state', value: 'state' },
  { type: 'type', value: 'type' },
  { type: 'active', value: 'active' },
];

const { useFormik } = actions;
export function Create() {
  const { onChange } = useFormik();
  const [currentStep, setCurrentstep] = useState(0);
  const [isEditing] = useState<boolean>(false);
  const { id } = useParams<{ id: string }>();
  const { data: dataCustomerById, isLoading, error } = GetCustomersById(id);

  useEffect(() => {
    if (dataCustomerById && !isLoading) {
      const data: any = dataCustomerById;
      KEYS.forEach(({ type, value }: any) => {
        onChange(`customer.createCustomers.${type}`, data[value], false);
      });
      if (data.modules) {
        onChange(
          `customer.createCustomers.modules`,
          data.modules.map(({ _id }: any) => _id),
          false,
        );
      }
      if (data.users) {
        onChange(
          `customer.createCustomers.users`,
          data.users.map(({ _id }: any) => _id),
          false,
        );
      }
      if (data.geolocation?.longitude) {
        onChange(`customer.createCustomers.geolocation.latitude`, data.geolocation.latitude, false);
      }
      if (data.geolocation?.longitude) {
        onChange(
          `customer.createCustomers.geolocation.longitude`,
          data.geolocation.longitude,
          false,
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  if (error) return <Alert message={error} type="error" />;

  return (
    <PageContent
      title={id ? I18n('update_customers', true, true) : I18n('create_customers', true, true)}
      backIcon={false}
      extra={[
        <WizardBody
          setCurrentstep={setCurrentstep}
          currentStep={currentStep}
          isEditing={id ? true : false}
        />,
      ]}
    >
      {currentStep !== 3 && (
        <Typography.Text type="secondary">{I18n('required_fields', true)}</Typography.Text>
      )}
      <Space.Layout>
        <Form layout="vertical" className="">
          <Space.Wrap hide={currentStep !== 0}>
            <InfoGeneral datCustomerById={dataCustomerById} disabled={isEditing} />
          </Space.Wrap>
          <Space.Wrap hide={currentStep !== 1}>
            <ConfigUsers disabled={isEditing} />
          </Space.Wrap>
          <Space.Wrap hide={currentStep !== 2}>
            <ConfigModules disabled={isEditing} />
          </Space.Wrap>
          <Space.Wrap hide={currentStep !== 3}>
            <FinalDetails />
          </Space.Wrap>
        </Form>
      </Space.Layout>
    </PageContent>
  );
}
