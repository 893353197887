import React from 'react';
import { Select as SelectAntd, Form } from 'antd';

const { Option, OptGroup } = SelectAntd;

interface NameProps {
  id: string;
  data: any[];
  label: string;
  allowClear?: boolean;
  showSearch?: boolean;
  altOptions?: boolean;
  disabled?: boolean;
  className?: string;
  loading?: boolean;
  keyOptions?: boolean;
  value?: string;
  notables?: any[];
  defaultValue?: string;
  style?: React.CSSProperties;
  all?: boolean;
  onChange: (e: any) => void;
  placeholder?: string;
  externalLabel?: string;
  isFormItem?: boolean;
  error?: string;
  touched?: boolean;
}

export const Select = (props: NameProps) => {
  let {
    data = [],
    value,
    defaultValue,
    notables = [],
    showSearch,
    allowClear,
    loading,
    label,
    keyOptions,
    altOptions,
    disabled,
    isFormItem,
    error,
    touched,
    externalLabel,
  } = props;
  let options: React.ReactNode[] = [
    <Option value={label} key="EMPTY">
      {label}
    </Option>,
  ];
  let alt_options: React.ReactNode[] = [];
  let catOptions: any[] = [];
  let ui_options: React.ReactNode[] = [];

  if (notables && notables.length) notables = notables.map((note) => note.value);

  if (data.length) {
    options = [];
    data.forEach((d: any, i: any) => {
      if (!d.value) return null;
      let style = {};
      if (props.altOptions && !notables.includes(d.label || d.value)) {
        alt_options.push(
          <Option style={style} value={d.value} key={d.label || d.value}>
            {d.label || d.value}
          </Option>,
        );
      } else {
        options.push(
          <Option style={style} value={d.value} key={d.label || d.value}>
            {d.label || d.value}
          </Option>,
        );
      }
    });

    if (props.all) {
      options.unshift(
        <Option value={'__ALL__'} disabled={true} key={-1}>
          ------
        </Option>,
      );
      options.unshift(
        <Option value={'__ALL__'} key={0}>
          {'select_all'}
        </Option>,
      );
    }
  }

  catOptions = options.concat(alt_options);
  if (catOptions.length < 2) value = catOptions[0]?.key;
  if (props.altOptions) {
    ui_options = [];
    if (label) {
      ui_options.push(
        <Option value={-2} key={-2} disabled>
          {label}
        </Option>,
      );
    }
    if (options.length)
      ui_options.push(
        <OptGroup key={'Opt1'} label={keyOptions}>
          {options}
        </OptGroup>,
      );
    if (alt_options.length)
      ui_options.push(
        <OptGroup key={'Opt2'} label={altOptions}>
          {alt_options}
        </OptGroup>,
      );
  } else {
    ui_options = options;
    if (label) {
      ui_options.unshift(
        <Option key={-2} value="label" disabled>
          {label}
        </Option>,
      );
    }
  }

  if (isFormItem) {
    return (
      <SelectAntd
        style={props.style}
        defaultValue={defaultValue}
        value={value}
        allowClear={allowClear}
        placeholder={label}
        showSearch={showSearch}
        loading={loading}
        filterOption={(input: any, option: any) => {
          return option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
        }}
        onChange={(e: any) => props.onChange(e)}
        disabled={disabled || catOptions.length < 2}
      >
        {ui_options}
      </SelectAntd>
    );
  }

  return (
    <Form.Item
      help={touched || error ? error : ''}
      validateStatus={touched || error ? 'error' : 'success'}
      label={externalLabel}
    >
      <SelectAntd
        style={props.style}
        defaultValue={defaultValue}
        value={value}
        allowClear={allowClear}
        placeholder={label}
        showSearch={showSearch}
        loading={loading}
        filterOption={(input: any, option: any) => {
          return option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
        }}
        onChange={(e: any) => props.onChange(e)}
        disabled={disabled || catOptions.length < 2}
      >
        {ui_options}
      </SelectAntd>
    </Form.Item>
  );
};
