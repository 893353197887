import { gql } from '@apollo/client';
import client from 'apollo/apollo-client';

const MUTATION_RESETPASSWORD = gql`
  mutation resetPassword($email: String!) {
    resetPassword(email: $email) {
      error
      data {
        _id
        name
        username
        email
        roles
        language
        verified
        active
      }
      message
    }
  }
`;

export async function resetPassword(email: string) {
  return await new Promise<{ error: boolean; data: any; message: string }>((resolve, reject) => {
    client
      .mutate({
        mutation: MUTATION_RESETPASSWORD,
        variables: { email },
      })
      .then(
        ({
          data: {
            resetPassword: { error, data, message },
          },
        }) => {
          resolve({ data, error, message });
        },
      )
      .catch((error) => {
        reject(new Error());
      });
  });
}
