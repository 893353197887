import { FC } from 'react';
import { PageHeader, Spin, Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import './style.less';

interface NameProps {
  title: string;
  loading?: boolean;
  onBack?: () => void;
  extra?: JSX.Element[];
  backIcon: boolean;
}

export const PageContent: FC<NameProps> = ({
  backIcon,
  title,
  children,
  extra,
  onBack,
  loading,
}) => {
  return (
    <div className="rk-page-container">
      <PageHeader
        className="rk-page-header"
        backIcon={[
          backIcon ? (
            <Button type="primary" icon={<FontAwesomeIcon icon={faArrowLeft} />} size="large" />
          ) : null,
        ]}
        onBack={onBack ? () => onBack() : undefined}
        title={title}
        extra={extra}
      />
      {!loading ? <div className="rk-page-content">{children}</div> : <Spin spinning={loading} />}
    </div>
  );
};
