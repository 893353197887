import { useMemo, useEffect } from 'react';
import { FormCard, SelectMultipleTags } from 'components';
import { useSelector, RootStateOrAny } from 'react-redux';
import { actions } from 'states';
import { IDataSource } from 'utils';
import { I18n } from 'i18n';

const { useFormik } = actions;
interface NameProps {
  disabled: boolean;
  roles?: string[];
}

export function PermissionsMobile({ disabled, roles }: NameProps) {
  const { onChange } = useFormik();
  const Roles = useSelector(({ roles }: RootStateOrAny) => roles);

  const listRolesMobiles: IDataSource[] = useMemo(() => {
    let memoRolesMobiles: IDataSource[] = [];
    Roles.forEach((data: any) => {
      if (!data.access.includes('mobile_client')) return;
      memoRolesMobiles.push({ key: data._id, value: data.name, label: I18n(data.name, true) });
    });
    return memoRolesMobiles;
  }, [Roles]);

  const dataAccesUserByMobile: string[] = useMemo(() => {
    let acces: string[] = [];
    if (roles) {
      listRolesMobiles.forEach((data: IDataSource) => {
        if (!roles.includes(data.value)) return;
        acces.push(data.value);
      }, []);
    }
    return acces;
  }, [roles, listRolesMobiles]);

  useEffect(() => {
    if (roles && dataAccesUserByMobile) {
      onChange(`user.createUser.accessMobile`, dataAccesUserByMobile, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roles, dataAccesUserByMobile]);

  return (
    <>
      <FormCard
        disabled={disabled}
        title={I18n('permissions_mobile_applications', true)}
        description={I18n('configure_the_permissions_that_the_user_will_have')}
      >
        <SelectMultipleTags
          isFormItem
          id="users-select-access-mobile"
          defaultValue={dataAccesUserByMobile}
          onChange={(value: string) => onChange(`user.createUser.accessMobile`, value, true)}
          data={listRolesMobiles}
          label={`${I18n('permissions_mobile_applications')}:*`}
        />
      </FormCard>
    </>
  );
}
