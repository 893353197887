import { useMemo } from 'react';
import { Form } from 'antd';
import { GetUserById } from 'apollo/users';
import { useParams } from 'react-router-dom';
import { Space, PageContent, Loading } from 'components';
import { I18n } from 'i18n';
import { Save, Information, PermissionsMobile, PermissionsWeb } from '../components';

interface NameProps {
  disabled: boolean;
}

export function CreateUser({ disabled }: NameProps) {
  const { id } = useParams<{ id: string }>();

  const { data: dataUserById, isLoading } = useMemo(() => {
    if (!id) {
      return { data: undefined, isLoading: false };
    }
    return GetUserById(id);
  }, [id]);

  if (id && isLoading) return <Loading loading={isLoading} />;

  return (
    <PageContent
      title={I18n('create_user', true, true)}
      backIcon={false}
      extra={[<Save userId={id} />]}
    >
      <Space.Layout>
        <Form layout="vertical" className="">
          <Information
            isLoading={isLoading}
            dataUserById={dataUserById ?? undefined}
            disabled={disabled}
          />
          <PermissionsWeb roles={dataUserById?.roles} disabled={disabled} />
          <PermissionsMobile roles={dataUserById?.roles} disabled={disabled} />
        </Form>
      </Space.Layout>
    </PageContent>
  );
}
