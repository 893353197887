import { ICreateUsers } from './interface';

export const CreateUser: ICreateUsers = {
  _id: '',
  name: '',
  username: '',
  address: '',
  roles: [],
  language: '',
  verified: false,
  active: false,
};
