import { Spin } from 'components';
import { Descriptions, Alert } from 'antd';
import { GetDataPointsById } from 'apollo/data-points';
import { I18n } from 'i18n';

import { DataPointState } from '../components';

interface NameProps {
  onCloseDrawer: () => void;
  codeId: string;
}
export function DataPointDetail({ codeId, onCloseDrawer }: NameProps) {
  const { data: dataDataPointById, loading: isLoading, error } = GetDataPointsById(codeId);
  if (codeId && error) return <Alert message={I18n('internal_server_error', true)} type="error" />;
  if (isLoading) return <Spin loading={isLoading} />;

  return (
    <>
      <Descriptions size="middle" column={1}>
        <Descriptions.Item label={`${I18n('name', true)} `}>
          {dataDataPointById.name}
        </Descriptions.Item>
        <Descriptions.Item label={`${I18n('customer', true)} `}>
          {dataDataPointById?.customer?.name}
        </Descriptions.Item>
        <Descriptions.Item label={`${I18n('longitude', true)}`}>
          {dataDataPointById.longitude}
        </Descriptions.Item>
        <Descriptions.Item label={`${I18n('latitude', true)}`}>
          {dataDataPointById.latitude}
        </Descriptions.Item>
        <Descriptions.Item label={`${I18n('module', true)}`}>
          {I18n(dataDataPointById.module, true)}
        </Descriptions.Item>
        <Descriptions.Item label={`${I18n('location', true)}`}>
          {dataDataPointById.location
            ? I18n(dataDataPointById.location, true)
            : I18n('no_location')}
        </Descriptions.Item>
      </Descriptions>
      <div className="rk-button-group">
        <DataPointState
          active={dataDataPointById.active}
          codeId={dataDataPointById._id}
          onCloseDrawer={onCloseDrawer}
        />
      </div>
    </>
  );
}
