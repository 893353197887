import { Steps as StepsAntd, Tooltip } from 'antd';
import { IDataSteps } from 'utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
// import { Tooltip, TooltipType, CheckMarkSvg } from "../";
import './style.less';
const { Step } = StepsAntd;

interface Props {
  direction: 'vertical' | 'horizontal';
  size: 'small' | 'default' | undefined;
  current: number;
  allChecked?: boolean;
  onChange: (current: number) => void;
  dataSteps: IDataSteps[];
}

export const Steps = (props: Props) => {
  const customDot = (
    text: string | number | React.ReactNode,
    tooltip: string,
    current: number,
    index: number,
  ) => {
    return (
      <div>
        <span>
          {current <= index && !props.allChecked ? (
            <Tooltip placement="bottom" title={tooltip}>
              <span>{text}</span>
            </Tooltip>
          ) : !props.allChecked ? (
            <FontAwesomeIcon icon={faCheck} />
          ) : (
            <Tooltip placement="bottom" title={tooltip}>
              <span className={current === index ? 'steps-item-active' : ''}>
                <FontAwesomeIcon icon={faCheck} />
              </span>
            </Tooltip>
          )}
        </span>
      </div>
    );
  };

  const stepDisable = (index: number) => {
    if (props.allChecked) {
      return !props.allChecked;
    } else {
      return props.current <= index;
    }
  };
  return (
    <>
      <StepsAntd
        className="rk-steps"
        direction={props.direction}
        size={props.size}
        current={!props.allChecked ? props.current : props.dataSteps.length}
        onChange={props.onChange}
        percent={100}
        initial={0}
      >
        {props.dataSteps.map((item, index) => (
          <Step
            key={index}
            className="step-item-before-none"
            disabled={stepDisable(index)}
            progressDot={() =>
              customDot(item.text || item.title, item.tooltip, props.current + 1, index + 1)
            }
          />
        ))}
        {props.allChecked ? (
          <div className="rk-circle all-checked"></div>
        ) : (
          <div className="rk-circle"></div>
        )}
      </StepsAntd>
    </>
  );
};
