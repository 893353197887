import { useState } from 'react';
import { I18n } from 'i18n';
import { Button } from 'antd';
import { Toast } from 'components';
import { ActiveIntegrator } from 'apollo/integrator';

interface NameProps {
  onCloseDrawer: () => void;
  integratorId: string;
  active: boolean;
}

export function ButtonActive({ integratorId, onCloseDrawer, active }: NameProps) {
  const [isLoading, setLoading] = useState(false);

  const handleClick = async () => {
    setLoading(true);
    const { data, error, message } = await ActiveIntegrator({ _id: integratorId });
    if (error) {
      Toast.error({ key: 'error-save-integrators', message: I18n(message, true) });
    }
    if (!error && data) {
      onCloseDrawer();
      Toast.success({
        key: 'success-save-integrators',
        message: I18n('your_changes_were_saved_successfully', true),
      });
    }
    setLoading(false);
  };

  return (
    <Button
      loading={isLoading}
      type="primary"
      className="rk-button-primary"
      disabled={isLoading}
      onClick={handleClick}
    >
      {active ? I18n('inactive_integrator', true) : I18n('active_integrator', true)}
    </Button>
  );
}
