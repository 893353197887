import { useMemo, useEffect } from 'react';
import { FormCard, Select } from 'components';
import { useSelector, RootStateOrAny } from 'react-redux';
import { actions } from 'states';
import { IDataSource } from 'utils';
import { I18n } from 'i18n';

const { useFormik } = actions;
interface NameProps {
  disabled: boolean;
  roles?: string[];
}

export function PermissionsWeb({ disabled, roles }: NameProps) {
  const { onChange } = useFormik();
  const Roles = useSelector(({ roles }: RootStateOrAny) => roles);

  const listRolesWebs: IDataSource[] = useMemo(() => {
    let memoRolesWebs: IDataSource[] = [];
    Roles.forEach((data: any) => {
      if (data.access.includes('mobile_client')) return;
      memoRolesWebs.push({ key: data._id, value: data.name, label: I18n(data.name, true) });
    });
    return memoRolesWebs;
  }, [Roles]);

  const dataAccesUserByWeb = useMemo(() => {
    let accesWeb: string = '';
    if (roles) {
      listRolesWebs.forEach(({ value }: IDataSource) => {
        if (roles.includes(value)) return (accesWeb = value);
      });
    }
    return accesWeb;
  }, [roles, listRolesWebs]);

  useEffect(() => {
    if (roles && dataAccesUserByWeb) {
      onChange(`user.createUser.accesWebs`, dataAccesUserByWeb, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roles, dataAccesUserByWeb]);

  return (
    <>
      <FormCard
        disabled={disabled}
        title={I18n('permissions_web_applications', true)}
        description={I18n('configure_the_permissions_that_the_user_will_have')}
      >
        <Select
          isFormItem
          defaultValue={dataAccesUserByWeb}
          style={{ width: '100%' }}
          id="users-select-roles-webs"
          onChange={(value: string) => onChange(`user.createUser.accesWebs`, value, true)}
          data={listRolesWebs}
          label={`${I18n('permissions_web_applications')}:*`}
        />
      </FormCard>
    </>
  );
}
