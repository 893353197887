import { Button } from 'antd';
import { I18n } from 'i18n';

interface NameProps {
  disabled: boolean;
  onClick: () => void;
}

export function ButtonNext(props: NameProps) {
  return (
    <Button
      type="primary"
      className="rk-button-primary"
      disabled={props.disabled}
      onClick={props.onClick}
    >
      {I18n('next', true)}
    </Button>
  );
}
