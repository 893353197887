import { useMemo } from 'react';
import moment from 'moment';
import { useSelector, RootStateOrAny } from 'react-redux';
import { List } from 'components';
import { Descriptions, Tag } from 'antd';
import { I18n } from 'i18n';
import { actions } from 'states';
import { GetUsers } from 'apollo/users';
import { IDataSource } from 'utils';

const { useFormik } = actions;

export function FinalDetails() {
  const { data: dataUsers } = GetUsers();
  const modules = useSelector(({ modules }: RootStateOrAny) => modules.data);
  const {
    values: {
      customer: { createCustomers },
    },
  } = useFormik();
  const weekStart = moment(new Date()).isoWeek();

  const selectModules = useMemo(() => {
    let memoModules: IDataSource[] = [];
    modules.forEach((data: any) => {
      if (!createCustomers.modules.includes(data._id)) return;
      memoModules.push({ key: data._id, value: data._id, label: I18n(data.name, true) });
    });
    return memoModules;
  }, [createCustomers.modules, modules]);

  const selectUsers = useMemo(() => {
    let memoUsers: IDataSource[] = [];
    dataUsers?.forEach((data: any) => {
      if (createCustomers.users && !createCustomers.users.includes(data._id)) return;
      memoUsers.push({ key: data._id, value: data._id, label: I18n(data.name, true) });
    });
    return memoUsers;
  }, [createCustomers.users, dataUsers]);

  return (
    <div
      className="rk-descriptions-info"
      style={{ border: '1px solid #DDE2ED', padding: 15, borderRadius: 4 }}
    >
      <Descriptions size="middle" column={1}>
        <Descriptions.Item label={`${I18n('name', true)}`}>
          {createCustomers.name}
        </Descriptions.Item>
        <Descriptions.Item label={`${I18n('week_start', true)}`}>{weekStart}</Descriptions.Item>
        <Descriptions.Item label={`${I18n('state', true)}`}>
          <Tag color={createCustomers.active ? 'green' : 'red'} key={createCustomers.active}>
            {createCustomers.active ? I18n('active', true) : I18n('inactive', true)}
          </Tag>
        </Descriptions.Item>
        <Descriptions.Item label={`${I18n('type', true)}`}>
          {I18n(createCustomers.type, true)}
        </Descriptions.Item>
      </Descriptions>
      <div className="rk-container-list">
        <List
          formGroupLabel={`${I18n('modules', true)}  `}
          readMoreLabel={I18n('you_can_see_them_in_detail')}
          data={selectModules}
        />
      </div>
      <div className="rk-container-list">
        <List
          formGroupLabel={`${I18n('users', true)}`}
          readMoreLabel={I18n('you_can_see_them_in_detail')}
          data={selectUsers}
        />
      </div>
    </div>
  );
}
