import { useMemo, useState, useEffect } from 'react';
import { Steps } from 'components';
import { IDataSteps } from 'utils';
import { I18n } from 'i18n';
import { actions } from 'states';
import { ButtonNext } from './button-next';
import { ButtonSave } from './button-save';
import './style.less';

interface NameProps {
  isEditing: boolean;
  currentStep: number;
  setCurrentstep: (current: number) => void;
}

const { useFormik } = actions;

export const WizardBody = (props: NameProps) => {
  const {
    values: {
      customer: { createCustomers },
    },
  } = useFormik();
  const [validateStep, setValidateStep] = useState<boolean>(true);
  const formHasChanged = false;
  const steps = useMemo<IDataSteps[]>(
    () => [
      {
        text: 1,
        tooltip: I18n('info_customer'),
      },
      {
        text: 2,
        tooltip: I18n('config_users'),
      },
      {
        text: 3,
        tooltip: I18n('config_modules'),
      },
      {
        text: 4,
        tooltip: I18n('final_details'),
      },
    ],
    [],
  );

  const handleClickNext = () => {
    props.setCurrentstep(props.currentStep + 1);
    setValidateStep(true);
  };

  const onChangeStep = (current: number) => {
    props.setCurrentstep(current);
  };

  const handleClickSubmit = () => {};

  useEffect(() => {
    switch (props.currentStep) {
      case 0:
        if (
          createCustomers['name-valid'] &&
          createCustomers['state-valid'] &&
          createCustomers['type-valid']
        ) {
          setValidateStep(
            createCustomers['name-valid'] &&
              createCustomers['state-valid'] &&
              createCustomers['type-valid'],
          );
        }
        break;
      case 1:
        setValidateStep(!createCustomers['users-valid']);
        break;
      case 2:
        setValidateStep(!createCustomers['modules-valid']);
        break;
      default:
        setValidateStep(true);
        break;
    }
  }, [createCustomers, props]);

  return (
    <div className="container-body">
      <div className="container-steps">
        <Steps
          direction="horizontal"
          size="small"
          allChecked={props.isEditing}
          current={props.currentStep}
          onChange={onChangeStep}
          dataSteps={steps}
        />
      </div>

      {props.currentStep === steps.length - 1 && !props.isEditing ? (
        <ButtonSave customersId="" disabled={!formHasChanged} onClick={handleClickSubmit} />
      ) : (
        <div className="container-button-extra">
          {props.isEditing ? (
            <ButtonSave customersId="" disabled={!formHasChanged} onClick={handleClickSubmit} />
          ) : (
            <ButtonNext
              disabled={!validateStep || props.currentStep === steps.length - 1}
              onClick={handleClickNext}
            />
          )}
        </div>
      )}
    </div>
  );
};
