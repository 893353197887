import { User } from 'types';
import { gql } from '@apollo/client';
import client from 'apollo/apollo-client';
import sha256 from 'sha256';

export async function saveUser(variables: User) {
  return await new Promise<{ loading: boolean; error: boolean; data: any; message: string }>(
    (resolve, reject) => {
      client
        .mutate({
          mutation: CONFIGURE_USERS,
          variables: { ...variables, password: sha256(variables.password || '') },
          refetchQueries: ['Users', 'UserById'],
        })
        .then(
          ({
            data: {
              UserSettings: { error, data, message },
            },
            loading,
          }: any) => {
            resolve({ data, loading, error, message });
          },
        )
        .catch((error) => {
          reject(new Error('_server_error_'));
        });
    },
  );
}

const CONFIGURE_USERS = gql`
  mutation UserSettings(
    $userId: ID
    $name: String!
    $userName: String!
    $email: String!
    $password: String
    $accesWebs: String
    $accessMobile: [String]
  ) {
    UserSettings(
      userId: $userId
      name: $name
      userName: $userName
      email: $email
      password: $password
      accesWebs: $accesWebs
      accessMobile: $accessMobile
    ) {
      error
      data {
        _id
        name
        username
        email
        roles
        language
        verified
        active
      }
      message
    }
  }
`;
