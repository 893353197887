import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { makeid } from 'utils';

let uri = `https://${process.env.REACT_APP_APOLLO_URI}-rk-apollo-server.irubiksas.co/graphql`;
if (process.env.REACT_APP_APOLLO_URI === 'local') uri = 'http://localhost:8000/graphql';

const httpLink = createHttpLink({ uri });
let userId = '';
const authLink = setContext(() => {
  const token = localStorage.getItem('auth:token');
  userId = localStorage.getItem('auth:userid') || '';
  return {
    headers: {
      authorizationToken: token,
      userid: userId,
      access: 'web_admin',
      'client-name': process.env.REACT_APP_NAME,
      'client-version': process.env.REACT_APP_VERSION,
      'browser-name': navigator.appName,
      'browser-version': navigator.appVersion,
    },
  };
});

const cache = new InMemoryCache({
  dataIdFromObject: ({ __typename }) => {
    let id = makeid(50);
    return `${__typename}-${id}`;
  },
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache,
});

export default client;
