import { useState } from 'react';
import { Button } from 'antd';
import { useHistory, Link } from 'react-router-dom';
import { PageContent, Drawer } from 'components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { CustomersList, DetailCustomers } from '../components';
import { I18n } from 'i18n';

export function Log() {
  const history = useHistory();
  const [farmsId, setFarmsId] = useState('');
  const [visible, setVisible] = useState(false);

  const onCloseDrawer = () => {
    setFarmsId('');
    setVisible(false);
  };

  const onShowDrawer = (value: string) => {
    if (!value) return setVisible(true);
    setFarmsId(value);
    setVisible(true);
  };
  return (
    <PageContent
      title={I18n('list_customers', true, true)}
      backIcon={false}
      extra={[
        <Button
          type="primary"
          className="rk-button-primary"
          onClick={() => history.push('/farms/create')}
        >
          {I18n('add', true)}
        </Button>,
      ]}
    >
      <CustomersList onShowDrawer={onShowDrawer} />
      <Drawer
        showTitle={true}
        title={farmsId ? I18n('farms_information', true, true) : I18n('farms_assigns', true, true)}
        placement="right"
        onClose={() => onCloseDrawer()}
        visible={visible}
        closeIcon={[
          <div style={{ display: 'flex', gap: 30 }}>
            {farmsId && (
              <Link to={`/farms/${farmsId}`}>
                <div>{I18n('edit', true)}</div>
              </Link>
            )}
            <FontAwesomeIcon icon={faTimesCircle} />
          </div>,
        ]}
      >
        <DetailCustomers customersId={farmsId} onCloseDrawer={onCloseDrawer} />
      </Drawer>
    </PageContent>
  );
}
